import { getAccountDetail, isAuthenticated } from 'data/api/auth.api';
import CompanyDetailNew from 'pages/company/company-detail/CompanDetailNew';
import { CompanyUserAddNew } from 'pages/company/company-employees-add/CompanyUserAddNew';
import { CompanyImportUser } from 'pages/company/company-import-users/CompanyImportUser';
import { CompanyNoteAdd } from 'pages/company/company-notes-add/CompanyNoteAdd';
import { NewCompanyUI } from 'pages/company/new-company/NewCompanyUI';
import { ProviderLayout } from 'pages/provider/layouts';
import { DetailProvider } from 'pages/provider/provider-detail';
import { ProviderList } from 'pages/provider/providers-list';
import NewUserNew from 'pages/user/pages/new-user/NewUserNew';
import UserEditNew from 'pages/user/pages/user-edit/UserEditNew';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAccount } from 'store/accountSlide';
import './App.scss';
import { ACCOUNT_ROUTES, DASHBOARD_ROUTES } from './Routes';
import { Main } from './layouts/Main/Main';
import { CompaniesList } from './pages/company/companies-list/CompaniesList';
import { CompaniesOverview } from './pages/company/companies-overview/CompaniesOverview';
import { CompanyNotes } from './pages/company/company-notes/CompanyNotes';
import { CompanyDetailLayout } from './pages/company/layouts/company-detail-layout';
import { LoginPage } from './pages/login/Login';
import { NotFoundPage } from './pages/not-found/NotFound';
import { UserDetailLayout } from './pages/user/layouts/user-detail-layout';
import UserLossEventDetail from './pages/user/pages/user-loss-event-detail/UserLossEventDetail';
import UserLossEvents from './pages/user/pages/user-loss-events/UserLossEvents';
import UserNoteAdd from './pages/user/pages/user-note-add/UserNoteAdd';
import { CompanyUsers } from 'pages/company/company-employees/CompanyUsers';
import { CompanyTrackingPage } from 'pages/company/company-tracking';
import { UserCheckIns } from 'pages/user/pages/user-check-ins';
import { UserTracking } from 'pages/user/pages/user-tracking';
import { DetailTaskList } from 'pages/template-task-list/detail';
import { TemplateTaskListLayout } from 'pages/template-task-list/layouts';
import { TaskList } from 'pages/template-task/list';
import { TemplateTaskLayout } from 'pages/template-task/layouts';
import { DetailTask } from 'pages/template-task/detail';
import { TaskListList } from 'pages/template-task-list/list';
import MigrationPage from 'pages/migrations/Main';
import { LoginPageDev } from 'pages/login/LoginDev';
import UserNotesNew from 'pages/user/pages/user-notes/UserNotesNew';
import { UserListNew } from 'pages/user/pages/user-list/UserListNew';

type Props = {
  children: JSX.Element;
};

const RequireAuthLayout: React.FC<Props> = ({ children }) => {
  const authed = isAuthenticated();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleFetchAccountDetail = async () => {
      try {
        const accountInfo = await getAccountDetail();
        dispatch(saveAccount(accountInfo));
      } catch (error) {
        console.error('Error: ', error);
      }
    };

    if (authed) {
      void handleFetchAccountDetail();
    }
  }, [dispatch, authed]);

  return authed ? children : <Navigate to={ACCOUNT_ROUTES.LOGIN} replace />;
};

const App: React.FC = () => (
  <div className="App">
    <Routes>
      <Route
        path={ACCOUNT_ROUTES.LOGIN}
        element={
          isAuthenticated() ? (
            <Navigate to={DASHBOARD_ROUTES.COMPANY_OVERVIEW} replace />
          ) : (
            <LoginPage />
          )
        }
      />
      <Route
        path={DASHBOARD_ROUTES.HOME}
        element={
          <RequireAuthLayout>
            <Main />
          </RequireAuthLayout>
        }
      >
        <Route
          path={'/'}
          element={<Navigate to={DASHBOARD_ROUTES.ROUTE_COMPANY} replace />}
        />

        {/*COMPANY*/}
        <Route
          path={DASHBOARD_ROUTES.ROUTE_COMPANY}
          element={<CompaniesOverview />}
        />
        <Route
          path={DASHBOARD_ROUTES.COMPANY_OVERVIEW}
          element={<CompaniesOverview />}
        />
        <Route
          path={DASHBOARD_ROUTES.COMPANY_LIST}
          element={<CompaniesList />}
        />

        <Route path={DASHBOARD_ROUTES.HOME} element={<CompanyDetailLayout />}>
          <Route
            path={DASHBOARD_ROUTES.NEW_COMPANY}
            element={<NewCompanyUI />}
          />
          <Route
            path={DASHBOARD_ROUTES.COMPANY_DETAIL_ID}
            element={<CompanyDetailNew />}
          />

          <Route
            path={DASHBOARD_ROUTES.ROUTE_COMPANY_EMPLOYEES_ID}
            element={<CompanyUsers />}
          />
          <Route
            path={DASHBOARD_ROUTES.ROUTE_COMPANY_EMPLOYEES_ADD}
            element={<CompanyUserAddNew />}
          />
          <Route
            path={DASHBOARD_ROUTES.ROUTE_COMPANY_EMPLOYEES_IMPORT}
            element={<CompanyImportUser />}
          />

          <Route
            path={DASHBOARD_ROUTES.ROUTE_COMPANY_NOTES}
            element={<CompanyNotes />}
          />
          <Route
            path={DASHBOARD_ROUTES.ROUTE_COMPANY_NOTES_ADD}
            element={<CompanyNoteAdd />}
          />
          <Route
            path={DASHBOARD_ROUTES.ROUTE_COMPANY_TRACKING}
            element={<CompanyTrackingPage />}
          />
        </Route>

        <Route
          path={DASHBOARD_ROUTES.ROUTE_PROVIDER}
          element={<ProviderList />}
        />
        <Route path={DASHBOARD_ROUTES.HOME} element={<ProviderLayout />}>
          <Route
            path={DASHBOARD_ROUTES.NEW_PROVIDER}
            element={<DetailProvider />}
          />
          <Route
            path={DASHBOARD_ROUTES.EDIT_PROVIDER}
            element={<DetailProvider />}
          />
        </Route>

        {/*USER*/}
        <Route path={DASHBOARD_ROUTES.ROUTE_USER} element={<UserListNew />} />
        <Route path={'/migrations'} element={<MigrationPage />} />

        <Route
          path={DASHBOARD_ROUTES.ROUTE_USER_LIST}
          element={<UserListNew />}
        />
        <Route
          path={DASHBOARD_ROUTES.ROUTE_USER_ADD}
          element={<NewUserNew />}
        />

        <Route
          path={DASHBOARD_ROUTES.ROUTE_USER}
          element={<UserDetailLayout />}
        >
          <Route
            path={DASHBOARD_ROUTES.ROUTE_USER_DETAIL_ID}
            element={<UserEditNew />}
          />

          <Route
            path={DASHBOARD_ROUTES.ROUTE_USER_NOTES}
            element={<UserNotesNew />}
          />
          <Route
            path={DASHBOARD_ROUTES.ROUTE_USER_NOTES_ADD}
            element={<UserNoteAdd />}
          />

          <Route
            path={DASHBOARD_ROUTES.ROUTE_USER_ID_LOSS_EVENTS}
            element={<UserLossEvents />}
          />
          <Route
            path={DASHBOARD_ROUTES.ROUTE_USER_ID_LOSS_EVENT_ID}
            element={<UserLossEventDetail />}
          />
          <Route
            path={DASHBOARD_ROUTES.ROUTE_USER_TRACKING}
            element={<UserTracking />}
          />
          <Route
            path={DASHBOARD_ROUTES.ROUTE_USER_ID_CHECK_INS}
            element={<UserCheckIns />}
          />
        </Route>
      </Route>
      <Route
        path="/"
        element={<Navigate replace to={ACCOUNT_ROUTES.LOGIN} />}
      />
      <Route path="*" element={<NotFoundPage />} />
      <Route path={DASHBOARD_ROUTES.LIST_TASK} element={<TaskList />} />
      <Route path={DASHBOARD_ROUTES.HOME} element={<TemplateTaskLayout />}>
        <Route path={DASHBOARD_ROUTES.NEW_TASK} element={<DetailTask />} />
        <Route path={DASHBOARD_ROUTES.EDIT_TASK} element={<DetailTask />} />
      </Route>

      <Route
        path={DASHBOARD_ROUTES.ROUTE_TASK_LIST}
        element={<TaskListList />}
      />
      <Route path={DASHBOARD_ROUTES.HOME} element={<TemplateTaskListLayout />}>
        <Route
          path={DASHBOARD_ROUTES.NEW_TASK_LIST}
          element={<DetailTaskList />}
        />
        <Route
          path={DASHBOARD_ROUTES.EDIT_TASK_LIST}
          element={<DetailTaskList />}
        />
      </Route>
    </Routes>

    <ToastContainer />
  </div>
);

export default App;
